require('./jquery.visible');

module.exports = make;
function make($cards, timeVisible, timeBetween) {


    $cards      = $cards || $('.card-flip-container');
    timeVisible = timeVisible || 2000;
    timeBetween = timeBetween || 200;

    var prevCard;

    $cards.on('touchstart', function () {
        $(this).toggleClass('locked');
    });

    $cards.on('mouseenter', function () {
        $cards.removeClass('hover');
        $(this).addClass('hover');

    });

    $cards.on('mouseleave', function () {
        $(this).removeClass('hover');
    });

    $cards.on('click', function () {
        $(this)
            .removeClass('hover')
            .removeClass('locked');
    });

    function flip() {

        var $validCards = $cards.filter(function () {
            var $card   = $(this);
            var hovered = $card.hasClass('hover');
            var visible = $card.visible();
            var prev    = this === prevCard;
            return !prev && !hovered && visible;
        });

        var card  = randomArrayItem($validCards);
        var $card = $(card);

        prevCard = card;

        $card.addClass('hover');

        setTimeout(function () {

            $card.removeClass('hover');

        }, timeVisible);

        setTimeout(function () {
            flip();
        }, timeVisible + timeBetween);
    }

    flip();
}

function randomArrayItem(array) {
    var index = Math.floor(Math.random() * array.length);
    return array[index];
}