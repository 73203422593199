$(function() {

    var $btns = $('.home-arrow-down');

    var $btn     = $('.btn-nav-menu'),
        $overlay = $('.nav-menu-overlay'),
        $wrap    = $('.site-container'),
        isClosed = true;

    $btn.click(function(e) {
        e.preventDefault();
        console.log('click');
        toggle();
    });

    $overlay.click(function() {
        close();
    });

    function open() {
        isClosed = false;

        $wrap.addClass('toggled');
        $overlay.stop(true, true).fadeIn();

        $btn
            .removeClass('is-closed')
            .addClass('is-open')

        $btns.hide();
    }

    function close() {
        isClosed = true;

        $wrap.removeClass('toggled');
        $overlay.stop(true, true).fadeOut();

        $btn
            .removeClass('is-open')
            .addClass('is-closed')

        $btns.show();
    }

    function toggle() {

        if (isClosed == true) {
            open();
        } else {
            close();
        }
    }

});