window.jQuery = window.$;

require('./lib/easing');
require('./lib/bootstrap');
require('./lib/match-height');
require('./nav-menu');
var flip   = require('./lib/card-flip');
var sr     = require('./lib/scroll-reveal');
var scroll = require('./lib/scroll');

window.sr = sr;

$(function () {

    var vHeight       = require('./lib/v-height');
    var onResize      = require('./lib/resize');
    //var slideCaptions = require('./lib/slide-captions');

    onResize.add(vHeight.resize);
    //onResize.add(slideCaptions.resize);
    onResize.trigger();

    $('.match-slides').addClass('start-intro');

    var settings = {
        distance: '40px',
    };

    var settingsRepeat = {
        distance: '40px',
        reset: true,
    };

    sr.reveal('.load-effect-repeat', settingsRepeat);
    sr.reveal('.load-effect', settings);

    $('[data-mh]').matchHeight();

    flip();

});

$(function(){


    console.log($('.btn-down-arrow'));
    $('.btn-down-arrow').on('click', function(e){
        e.preventDefault();
        var selector = $(this).attr('href');
        var $target = $(selector);

        console.log($target);
        scroll.scrollToEl($target)
    });


});

$(function () {

    var $target = $('.intro-continue-target');
    if (!$target.length) {
        return;
    }

    $('.intro-continue-click a').click(function (e) {
        e.stopPropagation();
    });

    $('.intro-continue-click, .btn-intro-continue').on('click', function (e) {
        scroll.scrollToEl($target)
    });


});

$(window).on('load', function(){
    $('.transparent-until-load').removeClass('transparent-until-load');
});
