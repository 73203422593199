
module.exports = {
	resize: resize,
};

var $vHeights   = $('.v-height');
var $vHeightsMin   = $('.v-height-min');
var $vHeightsMinFooter   = $('.site-inner');
var $vHeightTop = $('.v-height-top');
var $footer = $('.site-footer');
function resize(width, height) {
    $vHeights.height(height);
    $vHeightsMin.css('min-height', height);
    $vHeightTop.height(height - 80);

    $vHeightsMinFooter.css('min-height', height - $footer.height());

}
